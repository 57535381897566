import React from "react";
import styled from "styled-components";
import CategoryList from "../components/CategoryList";
import NewMoimList from "../components/NewMoimList";
import PopularMoimList from "../components/PopularMoimList";

const Wrapper = styled.div`
  max-width: 375px;
`;

const VoidBottom = styled.div`
  width: 100%;
  height: 40px;
`;

const Home = () => {
  return (
    <>
      <CategoryList />
      <NewMoimList />
      <PopularMoimList />
      <VoidBottom />
    </>
  );
};

export default Home;
