// 카카오
export const KAKAO_REDIRECT_URI = `${window.location.origin}/oauth/callback/kakao`;

console.log(process.env.PUBLIC_URL);

export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;

// 네이버
export const NAVER_REDIRECT_URI = `${
  process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "http://localhost:3000"
}/oauth/callback/naver`;

export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?client_id=${
  process.env.REACT_APP_NAVER_CLIENT_ID
}&response_type=code&redirect_uri=${NAVER_REDIRECT_URI}&state=${Math.random()
  .toString(36)
  .substr(3, 14)}`;
