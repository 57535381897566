import React, { useEffect, useState } from "react";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NewMoim from "./NewMoim";

const Wrapper = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MaxWrapper = styled.div`
  max-width: 375px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  font-family: PreSemiBold;
  font-weight: 600;
`;

const MoreBtn = styled.div`
  color: ${(props) => props.theme.subTextColor};
  display: flex;
  font-family: PreRegular;
  font-size: 13px;
  align-items: center;
`;

const MoimList = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 18.5px;
`;

// 가데이터
const moim_list = [
  {
    title: "도자기공방",
    contents: "도자기 구워봐용",
    area: "송파구",
    member: 282,
    label: "여행",
  },
  {
    title: "[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다! 나이는 숫자!",
    area: "송파구",
    member: 282,
    label: "여행",
  },
  {
    title:
      "[금정산] 봄꽃 가득한 매화마을 출발[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다!나도 여행가고 싶다..",
    area: "송파구",
    member: 282,
    label: "여행",
  },
];

export interface IMoim {
  title: string;
  contents: string;
  area: string;
  member: number;
  label: string;
}
/*홈 - 새 모임 리스트*/
function NewMoimList() {
  const [homeMoimList, setHomeMoimList] = useState<IMoim[]>(moim_list);

  useEffect(() => {
    if (moim_list.length > 10) {
      setHomeMoimList(moim_list.slice(0, 10));
    }
  }, []);

  return (
    <Wrapper>
      <MaxWrapper>
        <TitleWrapper>
          <Title>따끈따끈한 신규 모임~</Title>
          <MoreBtn>
            더보기 <KeyboardArrowRightIcon />
          </MoreBtn>
        </TitleWrapper>
        <MoimList>
          {homeMoimList.map((item, index) => (
            <NewMoim key={`new_moim_${index}`} item={item} />
          ))}
        </MoimList>
      </MaxWrapper>
    </Wrapper>
  );
}

export default NewMoimList;
