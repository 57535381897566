import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from "../Features/Home/compositions/Home";
import AuthRoute from "./AuthRoute";
import HomeLogout from "../Features/Home/compositions/HomeLogout";
import BottomNav from "../components/common/BottomNav";
import OAuth2RedirectHandler from "../modules/OAuth2RedirectHandler";
import Header from "../components/common/Header";

const Router = () => {
  return (
    <BrowserRouter>
      <Header />

      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/home_logout`}>
          <HomeLogout />
        </Route>
        <Route path="/oauth/callback/kakao">
          <Home />
          <OAuth2RedirectHandler />
        </Route>
        <AuthRoute path={`${process.env.PUBLIC_URL}/`}>
          <Home />
        </AuthRoute>
      </Switch>
      <BottomNav />
    </BrowserRouter>
  );
};

export default Router;
