// 로그인 route

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { KAKAO_REDIRECT_URI } from "../modules/OAuth";
import { useRecoilState } from "recoil";
import { accessTokenState } from "../state/auth";

interface AuthProps {
  children: React.ReactElement;
  path: string;
}
function AuthRoute(props: AuthProps) {
  const { children, path } = props;
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

  return (
    <>
      {accessToken || window.location.pathname.includes("code") ? (
        <Route path={path}>{children}</Route>
      ) : (
        <Redirect to={{ pathname: "/home_logout" }} />
      )}
    </>
    //   <Route
    //       {...props}
    //       render={(props)=>
    //           authenticated || window.location.pathname.includes('code') ? {children} :
    //       <Redirect to={{pathname:'/home_logout'}} />}
    //           />
  );
}

export default AuthRoute;
