import React, { useEffect, useState } from "react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

import { ReactComponent as SearchIcon } from "Assets/image/category/icon-search2.svg";
import { ReactComponent as MusicIcon } from "Assets/image/category/icon-music.svg";
import { ReactComponent as SportsIcon } from "Assets/image/category/icon-sports.svg";
import { ReactComponent as BookIcon } from "Assets/image/category/icon-book.svg";

import { ReactComponent as ArtIcon } from "Assets/image/category/icon-art.svg";
import { ReactComponent as ScissorsIcon } from "Assets/image/category/icon-scissors.svg";
import { ReactComponent as StudyIcon } from "Assets/image/category/icon-study.svg";
import { ReactComponent as TravelIcon } from "Assets/image/category/icon-travel.svg";

import { ReactComponent as MeetIcon } from "Assets/image/category/icon-meet.svg";
import { ReactComponent as JobIcon } from "Assets/image/category/icon-job.svg";
import { ReactComponent as CookIcon } from "Assets/image/category/icon-cook.svg";
import { ReactComponent as DogIcon } from "Assets/image/category/icon-dog.svg";

import { ReactComponent as DanceIcon } from "Assets/image/category/icon-dance.svg";
import { ReactComponent as CameraIcon } from "Assets/image/category/icon-camera.svg";
import { ReactComponent as VolunteerIcon } from "Assets/image/category/icon-volunteer.svg";
import { ReactComponent as GameIcon } from "Assets/image/category/icon-game.svg";

import { ReactComponent as LoveIcon } from "Assets/image/category/icon-love.svg";
import { ReactComponent as CarIcon } from "Assets/image/category/icon-car.svg";
import { ReactComponent as MenuIcon } from "Assets/image/category/icon-menu.svg";
import { ReactComponent as ExpandMoreIcon } from "Assets/image/common/icon-CaretDown.svg";
import Collapse from "@mui/material/Collapse";
import { styled as styled_mui } from "@mui/material/styles";
import styled from "styled-components";

// 가데이터
export const category = [
  {
    id: "search",
    icon: <SearchIcon />,
    title: "모임 검색",
  },
  {
    id: "music",
    icon: <MusicIcon />,
    title: "음악/악기",
  },
  {
    id: "sports",
    icon: <SportsIcon />,
    title: "운동/액티비티",
  },
  {
    id: "book",
    icon: <BookIcon />,
    title: "인문/독서",
  },

  {
    id: "art",
    icon: <ArtIcon />,
    title: "문화/예술",
  },
  {
    id: "scissors",
    icon: <ScissorsIcon />,
    title: "공예/제작",
  },
  {
    id: "study",
    icon: <StudyIcon />,
    title: "공부/자기계발",
  },
  {
    id: "travel",
    icon: <TravelIcon />,
    title: "여행",
  },

  {
    id: "meet",
    icon: <MeetIcon />,
    title: "사교/인맥",
  },
  {
    id: "job",
    icon: <JobIcon />,
    title: "업종/직무",
  },
  {
    id: "cook",
    icon: <CookIcon />,
    title: "요리/식생활",
  },
  {
    id: "dog",
    icon: <DogIcon />,
    title: "반려동물",
  },

  {
    id: "dance",
    icon: <DanceIcon />,
    title: "춤/무용",
  },
  {
    id: "camera",
    icon: <CameraIcon />,
    title: "사진/영상",
  },
  {
    id: "volunteer",
    icon: <VolunteerIcon />,
    title: "봉사활동",
  },
  {
    id: "game",
    icon: <GameIcon />,
    title: "게임",
  },

  {
    id: "love",
    icon: <LoveIcon />,
    title: "연애/사랑",
  },
  {
    id: "car",
    icon: <CarIcon />,
    title: "차/오토바이",
  },
  {
    id: "menu",
    icon: <MenuIcon />,
    title: "기타",
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 13px;
`;

const Button = styled_mui(IconButton)`
  background-color: inherit;
  &:not(:last-child) {
    margin-right: 18px;
  }
  width: 56px;
  height: 56px;
  &:hover {
    background-color: inherit;
  }
`;

const ButtonWrapper = styled.div`
  background-color: ${(props) => props.theme.subBgColor};
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-bottom: 6px;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: PreRegular;
  font-size: 12px;
`;

interface ICategory {
  id: string;
  icon: React.ReactElement;
  title: string;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled_mui((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MoreCategoryText = styled.span`
  color: ${(props) => props.theme.subTextColor};
  margin-top: 22px;
  font-size: 12px;
  font-family: PreRegular;
`;

/*Home - 모임 카테고리 리스트*/

function CategoryList() {
  const [noneCollapseCategory, setNoneCollapseCategory] = useState<ICategory[]>(
    []
  );
  const [collapseCategory, setCollapseCategory] = useState<ICategory[]>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  useEffect(() => {
    // 카테고리가 8개 이상이면 둘로 나누기
    if (category.length >= 8) {
      setNoneCollapseCategory(category.slice(0, 8));
      setCollapseCategory(category.slice(8));
    } else {
      setNoneCollapseCategory(category);
    }
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Wrapper>
        <ListWrapper>
          {noneCollapseCategory.map((item, index) => {
            return (
              <Category id={item?.id} key={`nc_category_${index}`}>
                <ButtonWrapper>
                  <Button>{item?.icon}</Button>
                </ButtonWrapper>
                {item?.title}
              </Category>
            );
          })}

          {collapseCategory.map((item, index) => {
            return (
              <Collapse
                key={`c_category_${index}`}
                id={item?.id}
                in={expanded}
                timeout={"auto"}
                unmountOnExit
              >
                <Category>
                  <ButtonWrapper>
                    <Button>{item?.icon}</Button>
                  </ButtonWrapper>
                  {item?.title}
                </Category>
              </Collapse>
            );
          })}
        </ListWrapper>

        {category.length >= 8 ? (
          <MoreCategoryText onClick={handleExpandClick}>
            {expanded ? "카테고리 접기" : "카테고리 펼치기"}
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </MoreCategoryText>
        ) : (
          <></>
        )}
      </Wrapper>
    </>
  );
}

export default CategoryList;
