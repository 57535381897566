// 리다이렉트될 화면
// OAuth2RedirectHandeler.ts

import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled as styled_mui } from "@mui/material";
import styled from "styled-components";
import { useQuery } from "react-query";
import { kakaoLoginCode } from "./api";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accessTokenState } from "../state/auth";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OAuth2RedirectHandler = () => {
  // 인가코드
  let code = new URL(window.location.href).searchParams.get("code") as string;

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

  const history = useHistory();

  const { isLoading, data } = useQuery("kakaoLogin", () => {
    kakaoLoginCode(code);
    console.log("code:::", code);
  });

  useEffect(() => {
    if (!isLoading) {
      setAccessToken("abcd");
      history.push("/");
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      )}
    </>
  );
};

export default OAuth2RedirectHandler;
