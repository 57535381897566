import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import styled from "styled-components";
import { styled as styled_mui } from "@mui/material";
import { ReactComponent as HomeEnabled } from "../../Assets/image/tab/icon-home-enabled.svg";
import { ReactComponent as HomeSelected } from "../../Assets/image/tab/icon-home-selected.svg";
import { ReactComponent as FeedEnabled } from "../../Assets/image/tab/icon-feed-enabled.svg";
import { ReactComponent as FeedSelected } from "../../Assets/image/tab/icon-feed-selected.svg";
import { ReactComponent as MoyeoEnabled } from "../../Assets/image/tab/icon-moyeo-enabled.svg";
import { ReactComponent as MoyeoSelected } from "../../Assets/image/tab/icon-moyeo-selected.svg";
import { ReactComponent as MyEnabled } from "../../Assets/image/tab/icon-my-enabled.svg";
import { ReactComponent as MySelected } from "../../Assets/image/tab/icon-my-selected.svg";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: solid 1px ${(props) => props.theme.borderColor};

  .Mui-selected {
    color: ${(props) => props.theme.tabSelectedColor};
    span {
      font-size: 10px;
    }
  }
`;

const NavBtn = styled_mui(BottomNavigationAction)`
height: 100%;
span{
   font-size: 10px;
   font-family: PreMedium;
}
`;

const Bottom = styled_mui(BottomNavigation)`
  height: 100%;
`;

const Void = styled.div`
  height: 60px;
`;

function BottomNav() {
  const [value, setValue] = React.useState(0);

  const menuList = [
    {
      label: "홈",
      icon_enabled: <HomeEnabled />,
      icon_selected: <HomeSelected />,
    },
    {
      label: "피드",
      icon_enabled: <FeedEnabled />,
      icon_selected: <FeedSelected />,
    },
    {
      label: "모여",
      icon_enabled: <MoyeoEnabled />,
      icon_selected: <MoyeoSelected />,
    },
    {
      label: "내 정보",
      icon_enabled: <MyEnabled />,
      icon_selected: <MySelected />,
    },
  ];

  return (
    <>
      <Wrapper>
        <Bottom
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {menuList.map((item, index) => (
            <NavBtn
              key={`menu_${index}`}
              label={item.label}
              icon={value === index ? item.icon_selected : item.icon_enabled}
            />
          ))}
        </Bottom>
      </Wrapper>
      <Void />
    </>
  );
}

export default BottomNav;
