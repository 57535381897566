import React from "react";
import { useQuery } from "react-query";
import { testAPI } from "./api";
// material-ui
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
// styled-components
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { defaultTheme } from "./theme";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routers/Router";
import GlobalStyle from "./styles/GlobalStyle";
import GlobalFont from "./styles/GlobalFont";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
function App() {
  // const { isLoading, data } = useQuery<test>("test", testAPI);

  return (
    <RecoilRoot>
      <StyledThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <GlobalFont />
        <HelmetProvider>
          <Router />
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={true} />
      </StyledThemeProvider>
    </RecoilRoot>
  );
}

export default App;
