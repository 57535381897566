import React, { MouseEventHandler, useEffect } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import { styled as styled_mui } from "@mui/material";
import kakaoImg from "Assets/image/snslogo/icon-logo-kakao.svg";
import naverImg from "Assets/image/snslogo/icon-logo-naver.svg";
import googleImg from "Assets/image/snslogo/icon-logo-google.svg";
import appleImg from "Assets/image/snslogo/icon-logo-apple.svg";
import { KAKAO_AUTH_URL, NAVER_AUTH_URL } from "modules/OAuth";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.subBgColor};
  width: 100%;
  padding-top: 94px;

  padding-bottom: 32px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MaxWrapper = styled.div`
  max-width: 375px;
`;

const Title = styled.div`
  font-family: PreSemiBold;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 24px;
  line-height: 1.35;
  padding-left: 24px;
  width: 375px;
`;

const SNSWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PreRegular;
  font-size: 12px;
  line-height: 1.45;
  color: ${(props) => props.theme.subTextColor};
`;

const SNSBtnList = styled.div`
  margin-top: 8px;
  display: flex;
`;

const SNSBtn = styled_mui(IconButton)<{ bgcolor: string }>`
  background-color: ${(props) => props.bgcolor};

  &:not(:last-child) {
    margin-right: 18px;
  }
  width: 56px;
  height: 56px;
   &:hover{
   background-color: ${(props) => props.bgcolor};
   }
   a{
    display:flex;
   }
`;

function SocialLoginInfo() {
  const snsList = [
    {
      id: "kakao",
      bgcolor: "#fae100",
      image: kakaoImg,
      url: KAKAO_AUTH_URL,
    },
    {
      id: "naverIdLogin",
      bgcolor: "#03c85b",
      image: naverImg,
      url: NAVER_AUTH_URL,
    },
    {
      id: "google",
      bgcolor: "#4285f4",
      image: googleImg,
      url: "",
    },
    {
      id: "apple",
      bgcolor: "#000000",
      image: appleImg,
      url: "",
    },
  ];

  const onClickSNSLoginBtn = (url: string) => {
    window.location.href = url;
  };

  return (
    <Wrapper>
      <MaxWrapper>
        {/*  타이틀 */}
        <Title>
          로그인하고
          <br />
          모임에 참가해보세요!
        </Title>
        {/*    SNS 로그인 버튼*/}
        <SNSWrapper>
          SNS 계정으로 간편 로그인
          <SNSBtnList>
            {snsList.map((item) => (
              <SNSBtn
                key={item.id}
                id={item.id}
                bgcolor={item.bgcolor}
                onClick={() => onClickSNSLoginBtn(item.url)}
              >
                <img src={item.image} />
              </SNSBtn>
            ))}
          </SNSBtnList>
        </SNSWrapper>
      </MaxWrapper>
    </Wrapper>
  );
}

export default SocialLoginInfo;
