import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { category } from "./CategoryList";
import { styled as styled_mui } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import PopularMoim from "./PopularMoim";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Wrapper = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MaxWrapper = styled.div`
  max-width: 375px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  font-family: PreSemiBold;
  font-weight: 600;
  margin-left: 16px;
`;

const CategoryList = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 16px;
`;

const Category = styled.div<{ selected: boolean }>`
  flex: 0 0 auto;
  margin-right: 8px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  &:first-child {
    margin-left: 16px;
  }
  &:last-child {
    margin-right: 16px;
  }
  background-color: ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.borderColor};
  .MuiButton-text {
    color: ${(props) =>
      props.selected ? props.theme.bgColor : props.theme.categoryTabTextColor};
    font-size: 13px;
    font-family: ${(props) => (props.selected ? "PreSemiBold" : "PreRegular")};
    font-weight: ${(props) => (props.selected ? 600 : "normal")};
  }
`;

const MoimList = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 16px;
  height: 267px;
`;

const MoreBtn = styled.div`
  color: ${(props) => props.theme.subTextColor};
  display: flex;
  font-family: PreRegular;
  font-size: 13px;
  align-items: center;
`;

// 가데이터
const moim_list = [
  {
    title: "도자기공방",
    contents: "도자기 구워봐용",
    area: "송파구",
    member: 282,
    id: "music",
  },
  {
    title: "[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다! 나이는 숫자!",
    area: "송파구",
    member: 282,
    id: "sports",
  },
  {
    title:
      "[금정산] 봄꽃 가득한 매화마을 출발[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다!나도 여행가고 싶다..",
    area: "송파구",
    member: 282,
    id: "book",
  },
  {
    title:
      "[금정산] 봄꽃 가득한 매화마을 출발[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다!나도 여행가고 싶다..",
    area: "송파구",
    member: 282,
    id: "art",
  },
  {
    title:
      "[금정산] 봄꽃 가득한 매화마을 출발[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다!나도 여행가고 싶다..",
    area: "송파구",
    member: 282,
    id: "scissors",
  },
  {
    title:
      "[금정산] 봄꽃 가득한 매화마을 출발[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다!나도 여행가고 싶다..",
    area: "송파구",
    member: 282,
    id: "study",
  },
  {
    title:
      "[금정산] 봄꽃 가득한 매화마을 출발[금정산] 봄꽃 가득한 매화마을 출발",
    contents: "매주 여행지는 달라집니다!나도 여행가고 싶다..",
    area: "송파구",
    member: 282,
    id: "study",
  },
];

interface IMoimList {
  title: string;
  contents: string;
  area: string;
  member: number;
  id: string;
}

/*홈 - 인기모임 리스트*/
function PopularMoimList() {
  const [selected, setSelected] = useState<string>("search");
  const [moimList, setMoimList] = useState<IMoimList[][]>([]);

  useEffect(() => {
    let _moim_list = [];

    if (selected === "search") {
      _moim_list = moim_list;
    } else {
      _moim_list = moim_list.filter((item) => item.id === selected);
    }

    if (_moim_list.length > 3) {
      const length = Math.floor(_moim_list.length / 3);
      const list = [];

      for (let i = 0; i < length; i++) {
        list.push(_moim_list.slice(3 * i, 3 + 3 * i));
      }
      setMoimList(list);
    } else {
      setMoimList([_moim_list]);
    }
  }, [selected]);

  const clickCategory = (category: string) => {
    setSelected(category);
  };

  return (
    <Wrapper>
      <MaxWrapper>
        <TitleWrapper>
          <Title>지금 가장 인기있어요!</Title>
          <MoreBtn>
            더보기 <KeyboardArrowRightIcon />
          </MoreBtn>
        </TitleWrapper>
        <CategoryList>
          <Category selected={selected === "search"}>
            <Button onClick={() => clickCategory("search")}>전체</Button>
          </Category>
          {category
            .filter((item) =>
              moim_list.map((item) => item.id).includes(item.id)
            )
            .map((item, index) => (
              <Category key={item.id} selected={selected === item.id}>
                <Button onClick={() => clickCategory(item.id)}>
                  {item.title}
                </Button>
              </Category>
            ))}
        </CategoryList>
        <MoimList>
          {moimList.map((item, index) => {
            return <PopularMoim key={`popular_moim_${index}`} item={item} />;
          })}
        </MoimList>
      </MaxWrapper>
    </Wrapper>
  );
}

export default PopularMoimList;
