import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styled_mui } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { IMoim } from "./NewMoimList";

const Wrapper = styled.div`
  width: 150px;
  margin-right: 16px;
  flex: 0 0 auto;
  &:first-child {
    margin-left: 16px;
  }
`;

const Photo = styled.div`
  background-color: ${(props) => props.theme.moimBlanckColor};
  width: 100%;
  height: 100px;
  border-radius: 12px;
  position: relative;
`;

const MoimChip = styled_mui(Chip)`
  position: absolute;
  right: 8px;
   
  height: 17px;
  top: 8px;
  color: #555;
  font-family: PreMedium;
  font-size: 11px;
  font-weight: 500;
`;

const Title = styled.div`
  margin-top: 12px;
  font-family: PreSemiBold;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.theme.tabSelectedColor};
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Contents = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.subTextColor};
  font-family: PreRegular;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const MoimInfo = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const Area = styled.div`
  color: ${(props) => props.theme.tabSelectedColor};
  font-family: PreMedium;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
`;

const MemberNumber = styled.div`
  font-family: PreMedium;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.accentColor};
  span {
    font-family: PreSemiBold;
    font-weight: 600;
  }
`;

const Ellipse = styled.div`
  width: 2px;
  height: 2px;
  flex-grow: 0;
  background-color: ${(props) => props.theme.subTextColor};
  margin-right: 4px;
`;

interface Props {
  item: IMoim;
}

/*새 모임 정보*/
function NewMoim({ item }: Props) {
  return (
    <Wrapper>
      <Photo>
        <MoimChip label={item.label} />
      </Photo>
      <Title>{item.title}</Title>
      <Contents>{item.contents}</Contents>
      <MoimInfo>
        <Area>{item.area}</Area>
        <Ellipse />
        <MemberNumber>
          멤버 <span>{item.member}</span>
        </MemberNumber>
      </MoimInfo>
    </Wrapper>
  );
}

export default NewMoim;
