import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  textColor: "#1f1f1f",
  subTextColor: "#949494",
  bgColor: "#ffffff",
  subBgColor: "#f8f7f3",
  borderColor: "#f5f5f5",
  tabSelectedColor: "#1a1a1a",
  moimBlanckColor: "#dfdfdf",
  accentColor: "#f88430",
  categoryTabTextColor: "#777777",
  popularMoimColor: "#efefef",
  popularMoimCategoryLabel: "#555555",
  moreBtnBorder: "#c8c8c8",
  makeMoimBtnBackgroundColor: "#f0ede6",
};
