import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styled_mui } from "@mui/material/styles";
import { category } from "./CategoryList";

const ThreeWrapper = styled.div`
  width: 300px;
  height: 73px;
  margin-right: 22px;
  &:first-child {
    margin-left: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Photo = styled.div`
  background-color: ${(props) => props.theme.popularMoimColor};
  width: 73px;
  height: 73px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.moimBlanckColor};
  margin-right: 15px;
`;

const MoimInfo = styled.div`
  display: flex;
  width: 212px;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: PreSemiBold;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.theme.tabSelectedColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const Contents = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.subTextColor};
  font-family: PreRegular;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const MoimAreaInfo = styled.div`
  margin-top: 4.5px;
  display: flex;
  align-items: center;
`;
const Area = styled.div`
  color: ${(props) => props.theme.tabSelectedColor};
  font-family: PreMedium;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
`;

const Ellipse = styled.div`
  width: 2px;
  height: 2px;
  flex-grow: 0;
  background-color: ${(props) => props.theme.subTextColor};
  margin-right: 4px;
`;

const MemberNumber = styled.div`
  font-family: PreMedium;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.accentColor};
  span {
    font-family: PreSemiBold;
    font-weight: 600;
  }
`;

const Category = styled.div`
  height: 17px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.popularMoimColor};
  color: ${(props) => props.theme.popularMoimCategoryLabel};
  font-size: 11px;
  font-weight: 500;
  font-family: PreMedium;
  padding: 1.5px 7px;
  margin-left: 8px;
`;

interface IMoim {
  title: string;
  contents: string;
  area: string;
  member: number;
  id: string;
}

interface IProps {
  item: IMoim[];
}

interface IChild {
  child: IMoim;
}

function PopularMoim({ item }: IProps) {
  const Child = ({ child }: IChild) => {
    return (
      <Wrapper>
        <Photo />
        <MoimInfo>
          <Title>{child.title}</Title>
          <Contents>{child.contents}</Contents>
          <MoimAreaInfo>
            <Area>{child.area}</Area>
            <Ellipse />
            <MemberNumber>
              멤버 <span>{child.member}</span>
            </MemberNumber>
            <Category>
              {category.find((item) => item.id === child.id)?.title}
            </Category>
          </MoimAreaInfo>
        </MoimInfo>
      </Wrapper>
    );
  };

  return (
    <ThreeWrapper>
      {item.map((child, index) => (
        <Child key={`popular_moim_child_${index}`} child={child} />
      ))}
    </ThreeWrapper>
  );
}

export default PopularMoim;
