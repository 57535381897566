// GlobalFont.ts
import { createGlobalStyle } from "styled-components";
// 각 폰트 파일 import
import PreBlack from "../Assets/fonts/Pretendard-Black.ttf";
import PreBold from "../Assets/fonts/Pretendard-Bold.ttf";
import PreExtraBold from "../Assets/fonts/Pretendard-ExtraBold.ttf";
import PreExtraLight from "../Assets/fonts/Pretendard-ExtraLight.ttf";
import PreLight from "../Assets/fonts/Pretendard-Light.ttf";
import PreMedium from "../Assets/fonts/Pretendard-Medium.ttf";
import PreRegular from "../Assets/fonts/Pretendard-Regular.ttf";
import PreSemiBold from "../Assets/fonts/Pretendard-SemiBold.ttf";
import PreThin from "../Assets/fonts/Pretendard-Thin.ttf";

export default createGlobalStyle`
  @font-face {
    font-family: 'PreBlack';
    src:  url(${PreBlack}) format('ttf');
  }

  @font-face {
    font-family: 'PreBold';
    src:  url(${PreBold}) format('ttf');
  }

  @font-face {
    font-family: 'PreExtraBold';
    src:  url(${PreExtraBold}) format('ttf');
  }

  @font-face {
    font-family: 'PreExtraLight';
    src:  url(${PreExtraLight}) format('ttf');
  }

  @font-face {
    font-family: 'PreLight';
    src:  url(${PreLight}) format('ttf');
  }
  @font-face {
    font-family: 'PreMedium';
    src:  url(${PreMedium}) format('ttf');
  }
  @font-face {
    font-family: 'PreRegular';
    src:  url(${PreRegular}) format('ttf');
  }
  @font-face {
    font-family: 'PreSemiBold';
    src:  url(${PreSemiBold}) format('ttf');
  }
  @font-face {
    font-family: 'PreThin';
    src:  url(${PreThin}) format('ttf');
  }
`;
