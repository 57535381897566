import React from "react";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Toolbar from "@mui/material/Toolbar";
import { styled as styled_mui } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";
import { ReactComponent as LinkIcon } from "../../Assets/image/header/icon-link.svg";
import { ReactComponent as ShareIcon } from "../../Assets/image/header/icon-share.svg";
import { ReactComponent as AlertIcon } from "../../Assets/image/header/icon-alert.svg";
import { ReactComponent as SpeakerIcon } from "../../Assets/image/header/icon_speaker.svg";
import { ReactComponent as PlusIcon } from "../../Assets/image/header/icon_plus.svg";
import { ReactComponent as Logo } from "../../Assets/image/header/logo_width.svg";
import { useRecoilState } from "recoil";
import { accessTokenState } from "../../state/auth";

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

const InitialToolbar = styled_mui(Toolbar)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
 padding:0;
   border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
 width: 375px;
 height: 178px;
`;

const CustomizeAppBar = styled_mui(AppBar)`
  padding:0;
  box-shadow: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
      background-color: #f8f7f3;
  color: #000000;
  display: flex;
  align-items: center;
`;

const CustomizeIconButton = styled_mui(IconButton)`
  padding:0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-contents: center;
  align-items: center;
  &:not(:last-child){
    margin-right: 12px;
  }
  &:last-child{
    margin-right: 10px;
  }
`;

const IconToolBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
`;

const IconToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const InitialTitleWrapper = styled.div`
  height: 118px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0px 18px;
  box-sizing: border-box;
`;

const InitialTitle = styled.span`
  color: ${(props) => props.theme.textColor};
  font-size: 20px;
  font-weight: 600;
  margin-top: 8px;
  font-family: PreSemiBold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 17px;
  svg {
    margin-left: 6px;
    display: inline-block;
  }
`;

const MakeMoimBtn = styled.div`
  cursor: pointer;
  width: 100%;
  height: 46px;
  background-color: ${(props) => props.theme.makeMoimBtnBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.tabSelectedColor};
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

const ReducedToolBar = styled_mui(Toolbar)`
  width: 375px;
  display: flex;
  justify-contents: space-between;
  padding:0;
  .logo{
    margin-left: 16px;
    width: 88px;
  height: 16.3px;
  }
`;

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ? children : <></>}
    </Slide>
  );
}

function ShowOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children ? children : <></>}
    </Slide>
  );
}

const ExpandedAppBar = () => {
  return (
    <InitialToolbar>
      <IconToolBarWrapper>
        <IconToolbar>
          <CustomizeIconButton>
            <LinkIcon />
          </CustomizeIconButton>
          <CustomizeIconButton>
            <ShareIcon />
          </CustomizeIconButton>
          <CustomizeIconButton>
            <AlertIcon />
          </CustomizeIconButton>
        </IconToolbar>
      </IconToolBarWrapper>
      <InitialTitleWrapper>
        <InitialTitle>
          다양한 모임이 당신을 기다려요 <SpeakerIcon />
        </InitialTitle>
        <MakeMoimBtn>
          <PlusIcon /> 모임만들기
        </MakeMoimBtn>
      </InitialTitleWrapper>
    </InitialToolbar>
  );
};

const ReducedAppBar = () => {
  return (
    <ReducedToolBar>
      <Logo className={"logo"} />
      <IconToolbar>
        <CustomizeIconButton>
          <LinkIcon />
        </CustomizeIconButton>
        <CustomizeIconButton>
          <ShareIcon />
        </CustomizeIconButton>
        <CustomizeIconButton>
          <AlertIcon />
        </CustomizeIconButton>
      </IconToolbar>
    </ReducedToolBar>
  );
};

function Header(props: Props) {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

  return (
    <>
      {accessToken ? (
        <>
          <HideOnScroll {...props}>
            <CustomizeAppBar>
              <ExpandedAppBar />
            </CustomizeAppBar>
          </HideOnScroll>
          <ShowOnScroll {...props}>
            <CustomizeAppBar>
              <ReducedAppBar />
            </CustomizeAppBar>
          </ShowOnScroll>
          <InitialToolbar />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
