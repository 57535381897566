import React from "react";
import SocialLoginInfo from "../components/SocialLoginInfo";
import CategoryList from "../components/CategoryList";
import NewMoimList from "../components/NewMoimList";
import styled from "styled-components";
import PopularMoimList from "../components/PopularMoimList";

const Void = styled.div`
  width: 100%;
  height: 40px;
`;

/* 비회원 페이지 */
const HomeLogout = () => {
  return (
    <>
      <SocialLoginInfo />
      <CategoryList />
      <NewMoimList />
      <PopularMoimList />
      <Void />
    </>
  );
};

export default HomeLogout;
